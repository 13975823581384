<template>
	<v-container>
		<v-row>
			<v-col cols="4">
				<v-card flat :color="getColor('client')" min-height="100%">
					<v-card-title><v-icon class="mr-2">mdi-account</v-icon>Клиент</v-card-title>
					<v-card-text>
						<v-text-field outlined dense hide-details class="mt-0" label="Номер" v-model="input.clientNo" @input="searchClient"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="Име" v-model="input.clientName" @input="searchClient"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="Телефон" v-model="input.clientPhone" @input="searchClient"></v-text-field>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="4">
				<v-card flat :color="getColor('imot')" min-height="100%">
					<v-card-title><v-icon class="mr-2">mdi-home</v-icon>Имот</v-card-title>
					<v-card-text>
						<v-text-field outlined dense hide-details class="mt-0" label="Номер имот" v-model="input.imotNo" @input="searchImot"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="Адрес" v-model="input.imotAddress" @input="searchImot"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="ID" v-model="input.imotId" @input="searchImot"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="Номер у-во" v-model="input.deviceNo" @input="searchImot"></v-text-field>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="4">
				<v-card flat :color="getColor('station')" min-height="100%">
					<v-card-title><v-icon class="mr-2">mdi-pipe</v-icon>Станция</v-card-title>
					<v-card-text>
						<v-text-field outlined dense hide-details class="mt-0" label="Номер" v-model="input.stationNo" @input="searchStation"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="Сграда" v-model="input.buildingNo" @input="searchStation"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="Адрес" v-model="input.stationAddress" @input="searchStation"></v-text-field>
						<v-text-field outlined dense hide-details class="mt-4" label="ID" v-model="input.stationId" @input="searchStation"></v-text-field>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<component v-if="active" :is="'search-' + active" v-bind="props" />
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import SearchClient from "./SearchClient";
import SearchImot from "./SearchImot";
import SearchStation from "./SearchStation";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import {RolesEnum} from "@/ittijs/Authorization";

export default {
	components: {
		SearchClient,
		SearchImot,
		SearchStation,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
	],
	inject: ['authorization'],
	props: [
		'utility',
	],
	data: ()=>({
		input: {
			clientNo: '',
			clientName: '',
			clientPhone: '',
			imotNo: '',
			imotAddress: '',
			imotId: '',
			deviceNo: '',
			stationNo: '',
			buildingNo: '',
			stationAddress: '',
			stationId: '',
		},
	}),
	computed: {
		active(){
			if (this.input.clientNo != '' || this.input.clientName != '' || this.input.clientPhone != '') {
				return 'client';
			}
			if (this.input.imotNo != '' || this.input.imotAddress != '' || this.input.imotId != '' || this.input.deviceNo != '') {
				return 'imot';
			}
			if (this.input.stationNo != '' || this.input.buildingNo != '' || this.input.stationAddress != '' || this.input.stationId != '') {
				return 'station';
			}
			return null;
		},
		routeName(){
			if (this.authorization.hasAnyRole([RolesEnum.OTCHETI_FULL,RolesEnum.OTCHETI_BASE])) {
				return 'account';
			}
			return 'frontdesk';
		},
		props(){
			switch (this.active) {
				case 'client':
					return {
						number: this.input.clientNo,
						name: this.input.clientName,
						phone: this.input.clientPhone,
						routeName: this.routeName,
					}
				case 'imot':
					return {
						number: this.input.imotNo,
						address: this.input.imotAddress,
						id: this.input.imotId,
						deviceNo: this.input.deviceNo,
						routeName: this.routeName,
					}
				case 'station':
					return {
						number: this.input.stationNo,
						building: this.input.buildingNo,
						address: this.input.stationAddress,
						id: this.input.stationId,
						routeName: this.routeName,
					}
			}
			return {}
		},
	},
	methods: {
		searchClient(){
			this.input.imotNo = '';
			this.input.imotAddress = '';
			this.input.imotId = '';
			this.input.deviceNo = '';
			this.input.stationNo = '';
			this.input.buildingNo = '';
			this.input.stationAddress = '';
			this.input.stationId = '';
		},
		searchImot(){
			this.input.clientNo = '';
			this.input.clientName = '';
			this.input.clientPhone = '';
			this.input.stationNo = '';
			this.input.buildingNo = '';
			this.input.stationAddress = '';
			this.input.stationId = '';
		},
		searchStation(){
			this.input.imotNo = '';
			this.input.imotAddress = '';
			this.input.imotId = '';
			this.input.deviceNo = '';
			this.input.clientNo = '';
			this.input.clientName = '';
			this.input.clientPhone = '';
		},
		getColor(active) {
			if (active == this.active) {
				return 'headings';
			}
			else {
				return 'headings2';
			}
		}
	},
}
</script>