<template>
	<list hide-delete hide-edit hide-select
		:api-debounce-time="500"
		headers="utility_code,client_N,client_type,client_name,client_phone,client_phone2,client_email"
		:filter="filter"
		:item-class="()=>'cursor-pointer'"
		@click:row="clickRow"
	/>
</template>

<style>
tr.cursor-pointer > td {
	cursor: pointer;
}
</style>

<script>
import List from "@/ittijs/List";
import Model from "../imot/Model";
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const imodel = ITTIModelProvider.getModel(Model);

export default {
	components: {List},

	mixins: [
		SelectedUtilityMixin,
	],

	provide: function () {
		return {
			imodel,
		};
	},

	props: {
		number: String,
		name: String,
		phone: String,
		routeName: String,
	},

	computed: {
		filter(){
			return {
				glue: "AND",
				rules: [
					{
						field: "utility_code",
						op: "eq",
						value: this.selectedUtility,
					},
					{
						field: "client_N",
						op: "bw",
						value: this.number || null,
					},
					{
						field: "client_name",
						op: "kw",
						value: this.name || null,
					},
				],
				groups: [{
					glue: "OR",
					rules: [
						{
							field: "client_phone",
							op: "kw",
							value: this.phone || null,
						},
						{
							field: "client_phone2",
							op: "kw",
							value: this.phone || null,
						},
					],
				}],
			}
		},
	},

	methods: {
		async clickRow(row){
			const data = await imodel.getLatestSessionInfo(row.id);
			if (data !== null) {
				this.$router.push({
					name: this.routeName,
					params: {
						utility: data.utility,
						session: data.session,
						station: data.station,
						imot: data.imot,
					},
				});
			}
		},
	},

}
</script>