<template>
	<list hide-delete hide-edit hide-select
		:api-debounce-time="500"
		headers="utility_code,imot_N,imot_type,address,location"
		:filter="filter"
		:params="{deviceNo}"
		:item-class="()=>'cursor-pointer'"
		@click:row="clickRow"
	/>
</template>

<style>
tr.cursor-pointer > td {
	cursor: pointer;
}
</style>

<script>
import List from "@/ittijs/List";
import Model from "../imot/Model";
import SelectedUtilityMixin from '@/ittijs/SelectedUtilityMixin';
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const imodel = ITTIModelProvider.getModel(Model);

export default {
	components: {List},

	mixins: [
		SelectedUtilityMixin,
	],

	provide: function () {
		return {
			imodel,
		};
	},

	props: {
		number: String,
		address: String,
		id: String,
		deviceNo: String,
		routeName: String,
	},

	computed: {
		filter(){
			return {
				glue: "AND",
				rules: [
					{
						field: "utility_code",
						op: "eq",
						value: this.selectedUtility,
					},
					{
						field: "address",
						op: "kw",
						value: this.address || null,
					},
					{
						field: "id",
						op: "eq",
						value: this.id || null,
					},
				],
				groups: [
					{
						glue: "OR",
						rules: [
							{
								field: "imot_N",
								op: "bw",
								value: this.number || null,
							},
							{
								field: "imot_N_alt",
								op: "bw",
								value: this.number || null,
							},
						],
					}
				],
			}
		},
	},

	methods: {
		async clickRow(row){
			const data = await imodel.getLatestSessionInfo(row.id);
			if (data !== null) {
				this.$router.push({
					name: this.routeName,
					params: {
						utility: data.utility,
						session: data.session,
						station: data.station,
						imot: data.imot,
					},
				});
			}
		},
	},

}
</script>